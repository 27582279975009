import {
  BookOpenIcon,
  HeadphonesIcon,
  UserIcon,
  LinkedinIcon,
} from "lucide-react";
import React, { useState } from "react";
import { render } from "react-dom";
export default function BookPromo() {
  const [showAuthorInfo, setShowAuthorInfo] = useState(false);
  return (
    <div
      className="min-h-screen bg-gradient-to-r from-orange-500 to-teal-500 flex flex-col items-center justify-center text-white"
      data-id="element-0"
    >
      <header className="text-center mb-8" data-id="element-1">
        <h1 className="text-4xl md:text-6xl font-bold mb-4" data-id="element-2">
          EMPOWERING HUMANITY
        </h1>
        <h2 className="text-2xl md:text-4xl mb-2" data-id="element-3">
          IN THE AGE OF
        </h2>
        <h1 className="text-4xl md:text-6xl font-bold" data-id="element-4">
          ARTIFICIAL INTELLIGENCE
        </h1>
      </header>

      <main className="w-full max-w-4xl px-4" data-id="element-5">
        <div
          className="flex flex-col md:flex-row justify-between mb-12"
          data-id="element-6"
        >
          <div className="w-full md:w-1/2 mb-8 md:mb-0" data-id="element-7">
            <img
              src="book_cover.png"
              alt="Book Cover"
              className="w-full h-auto rounded-lg shadow-lg"
              data-id="element-8"
            />
          </div>
          <div
            className="w-full md:w-1/2 md:pl-8 flex flex-col justify-center"
            data-id="element-9"
          >
            <h3 className="text-2xl font-semibold mb-4" data-id="element-10">
              By Chris Allen
            </h3>
            <p className="mb-6" data-id="element-11">
              Explore the future of humanity in the era of AI. This
              groundbreaking book offers insights into how we can harness the
              power of artificial intelligence while maintaining our human
              essence.
            </p>
            <div
              className="grid grid-cols-1 md:grid-cols-2 gap-4"
              data-id="element-12"
            >
              {[1, 2, 3, 4].map((chapter) => (
                <a
                  key={chapter}
                  href={`chapter.pdf#page=${chapter === 2 ? 29 : chapter === 3 ? 42 : chapter === 4 ? 68: chapter}`}
                  className="flex items-center justify-center bg-white bg-opacity-20 rounded-lg p-4 hover:bg-opacity-30 transition-colors"
                  data-id="element-13"
                >
                  <BookOpenIcon className="mr-2" data-id="element-14" />
                  <span data-id="element-15">Chapter {chapter}</span>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="text-center mb-12" data-id="element-16">
          <h3 className="text-2xl font-semibold mb-4" data-id="element-17">
            Listen to Audio Chapters
          </h3>
          <div
            className="flex flex-col md:flex-row justify-center gap-4"
            data-id="element-18"
          >
            <a
              href="chapters123.mp3"
              className="flex items-center justify-center bg-white bg-opacity-20 rounded-lg p-4 hover:bg-opacity-30 transition-colors"
              data-id="element-19"
            >
              <HeadphonesIcon className="mr-2" data-id="element-20" />
              <span data-id="element-21">Chapters 1-3 Audio</span>
            </a>
            <a
              href="chapter4.mp3"
              className="flex items-center justify-center bg-white bg-opacity-20 rounded-lg p-4 hover:bg-opacity-30 transition-colors"
              data-id="element-22"
            >
              <HeadphonesIcon className="mr-2" data-id="element-23" />
              <span data-id="element-24">Chapter 4: Classroom of 2035</span>
            </a>
          </div>
        </div>

        <div className="text-center mb-12" data-id="element-0">
          <button
            onClick={() => setShowAuthorInfo(!showAuthorInfo)}
            className="flex items-center justify-center bg-white bg-opacity-20 rounded-lg p-4 hover:bg-opacity-30 transition-colors mx-auto"
            data-id="element-1"
          >
            <UserIcon className="mr-2" data-id="element-2" />
            <span data-id="element-3">About the Author</span>
          </button>
          {showAuthorInfo && (
            <div
              className="mt-4 bg-white bg-opacity-20 rounded-lg p-4"
              data-id="element-4"
            >
              <p className="mb-4" data-id="element-5">
		  <b><i><a href="mailto:Chris@CareerHacks.Ai">Chris Allen</a></i></b> stands at the forefront of the AI revolution as a visionary futurist, strategic thinker, and impassioned advocate for the ethical integration of artificial intelligence into society. With a career spanning three decades in technology and grassroots political advocacy, Allen brings a unique perspective to the intersection of technology, policy, and human potential.
As the founder and CEO of CareerHacks.AI and AI-Realized.com, Allen has established himself as a pioneering force in AI education and implementation. His companies are dedicated to bridging the gap between cutting-edge AI technologies and practical, real-world applications, empowering individuals and organizations to thrive in an AI-driven future.
Allen's expertise extends far beyond the boardroom. As a sought-after public speaker and AI evangelist, he has captivated audiences with his profound insights into AI development, its societal implications, and the ethical considerations that must guide its progress. His ability to distill complex technological concepts into accessible, actionable strategies has made him a trusted advisor to business leaders, policymakers, and educators alike.
What sets Allen apart is his holistic vision for a future where human intelligence and artificial intelligence coexist harmoniously. Drawing from his extensive background in political advocacy, he understands the delicate balance required to navigate the societal changes brought about by technological advancements. This unique blend of technological acumen and social consciousness infuses his writing with both practical wisdom and inspirational foresight.
In "Empowering Humanity in the Age of Artificial Intelligence," Allen doesn't just predict the future—he provides a roadmap for actively shaping it. His work is a testament to his unwavering commitment to ensuring that the AI revolution enhances human potential rather than diminishing it.
As we stand on the brink of unprecedented technological change, Chris Allen emerges as a guiding voice, illuminating the path toward a future where humanity and AI together create a world of boundless possibility and shared prosperity.
              </p>
              <a
                href="https://www.linkedin.com/in/AiEvangalist/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-blue-600 rounded-lg p-2 hover:bg-blue-700 transition-colors"
                data-id="element-6"
              >
                <LinkedinIcon className="mr-2" data-id="element-7" />
                <span data-id="element-8">Connect on LinkedIn</span>
              </a>
            </div>
          )}
        </div>
      </main>

      <footer className="text-center mt-auto pb-8" data-id="element-25">
        <p data-id="element-26">
          &copy; 2024 <b><i><a href="mailto:Chris@CareerHacks.Ai">Chris Allen</a></i></b>. All rights reserved.
        </p>
      </footer>
    </div>
  );
}
render(<BookPromo data-id="element-9" />, document.getElementById("root"));
