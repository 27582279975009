import logo from './logo.svg';
import './output.css';
import BookPromo from './BookPromo';

function App() {
  return (
    <div className="App">
      <BookPromo />
    </div>
  );
}

export default App;
